import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ApplySection from "../components/shared/apply-section"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/think-before-you-adopt/sections"
import banner from "../images/think-dogs-banner-bg.jpg"
const ThinkAdoptPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Think Before You Adopt" />
    <InnerBanner theme="white" image={banner} title="Think Before You Adopt" subtitle="Get Involved" />
    <Sections.Section1/>

      <ApplySection theme="adoption-apply">
           <Link className="btn-accent btn-volunteer" to="/adoption-process/">Apply for Adoption</Link>
    </ApplySection>
    <HowToHelp />
  </Layout>
}

export default ThinkAdoptPage