import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./sections.css"
const Section1 = ()=>{
    
    return <section className="think-sec1"> 
    <div className="content">
        <div className="content-text">
            <h2>Things to consider...</h2>
            <p className="large">(Courtesy of the Plano Animal Shelter)<br/>
            <strong>Please ask yourself the following questions:</strong></p>
            <ol>
                <li>Do I really want a dependent for the next 10-15 years?</li>
                <li>Do I have the patience to house train an animal? Do I know how to house train an animal?</li>
                <li>Do I want to be involved in basic obedience training that will make life easier for both of us?</li>
                <li>Do I have the spare time in my day or evening to spend returning my pets’ companionship needs?</li>
                <li>Who will care for my pet when I am away?</li>
                <li>What happens if I am late getting home?</li>
                <li>Can I commit to exercising my dog at least twice a day?</li>
                <li>Can I afford approximately $500 per year in basic health-care costs (annual shots, check-ups, heartworm preventative)?</li>
                <li>Can I afford the unexpected expenses for illnesses or accidents?</li>
                <li>Am I prepared to perform, or can I afford, grooming, bathing and dipping regularly?</li>
                <li>What if fleas or ticks become a problem in my yard or house?</li>
                <li>Does my budget allow for necessities such as quality pet food, collars, leashes, bowls, carriers and toys?</li>
                <li>What if the personality or size of the pet as an adult is not what I wanted?</li>
                <li>Is anyone in my family allergic to cats or dogs?</li>
                <li>How will life be different with a young, small puppy or kitten or a larger adult animal?</li>
                <li>What if my pet digs in my yard or scratches or claws at the furniture?</li>
                <li>What if my dog barks or howls a lot?</li>
                <li>Am I willing to clean up the messes my pet creates?</li>
                <li>What happens to my pet if I move?</li>
                <li>Does everyone in my family or household like the idea of having a pet?</li>
                <li>What if I have children? How will this affect my pets?</li>
            </ol>
        </div>
        <div className="content-images">
            <StaticImage src="../../images/img-circledog-think.png" layout="constrained"/>
            <div className="more-images">
                <span className="thumb"><StaticImage src="../../images/things-to-consider-img-1.jpg" layout="constrained"/></span>
                 <span className="thumb"><StaticImage src="../../images/things-to-consider-img-2.jpg" layout="constrained"/></span>
                 <span className="thumb"><StaticImage src="../../images/things-to-consider-img-3.jpg" layout="constrained"/></span>
                 <span className="thumb"><StaticImage src="../../images/things-to-consider-img-4.jpg" layout="constrained"/></span>
            </div>
        </div>
    </div>
    </section>

}

const Sections = {
Section1
}

export default Sections